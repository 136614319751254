var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cardRechargeContent"},[_c('div',{staticClass:"cardRechargeContainer"},[_c('NavHeader'),_c('NavHeaderMobile'),_c('div',{staticClass:"cardRechargeContainerContent"},[_c('div',{staticClass:"backContainer",on:{"click":_vm.backBtn}},[_c('a-icon',{attrs:{"type":"arrow-left"}}),_vm._v(" back ")],1),_c('div',{staticClass:"stepContent"},[_c('div',{staticClass:"stepContainer"},[_vm._m(0),_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-space',{staticClass:"inputContent",attrs:{"size":20,"direction":"vertical"}},[_c('a-space',{staticClass:"inputContent inputPayment",attrs:{"size":10,"direction":"vertical"}},[_c('div',{staticClass:"inputLabel"},[_c('div',{staticClass:"inputLabelText"},[_vm._v("Deposit Assets")]),_c('a-select',{attrs:{"value":_vm.currency},on:{"change":_vm.currencyChange}},_vm._l((_vm.currencyOption),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_c('img',{style:({
                          height: '24px',
                          width: '24px',
                          marginRight: '5px',
                          marginTop: '-3px',
                        }),attrs:{"src":item.logo}}),_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1),(_vm.depositTransactionFee)?_c('div',{staticClass:"depositTips"},[_vm._v(" *Deposit Transaction Fee: "+_vm._s(_vm.depositTransactionFee)+" % ")]):(_vm.cardTypeId!=='60000004')?_c('div',{staticClass:"depositTips"},[_vm._v("*Deposit Transaction Fee: 2 %")]):(_vm.cardTypeId==='60000004')?_c('div',{staticClass:"depositTips"},[_vm._v("*Deposit Transaction Fee: 2.5 % (Minimum Fee: 1 USD)")]):_vm._e(),_c('div',{staticClass:"inputPaymentTips"},[_c('span',{staticClass:"tipsLeft"},[_vm._v("Deposit Address: ")]),_c('span',{staticClass:"addressText"},[_vm._v(_vm._s(_vm.address))]),_c('div',{staticClass:"copyBtn",attrs:{"data-clipboard-text":_vm.address}},[_c('a-icon',{staticClass:"copyAddress",attrs:{"type":"copy","theme":"twoTone"}})],1)])])],1),_c('div',{staticClass:"stepsBottomTips"},[(_vm.currency === 'USDT-TRC20')?_c('span',[_vm._v("*Ensure the network for withdrawal is Tron(TRC20).")]):_vm._e(),(_vm.currency === 'NEO')?_c('span',[_vm._v("*Ensure the network for withdrawal is NEO(N3).")]):_vm._e(),(_vm.currency === 'GAS')?_c('span',[_vm._v("*Ensure the network for withdrawal is GAS(N3).")]):_vm._e(),(_vm.currency === 'USDT-TRC20')?_c('span',[_vm._v("*Send only USDT-TRC20 to this deposit address.")]):_vm._e(),(_vm.currency === 'NEO')?_c('span',[_vm._v("*Send only NEO to this deposit address.")]):_vm._e(),(_vm.currency === 'GAS')?_c('span',[_vm._v("*Send only GAS to this deposit address.")]):_vm._e(),_c('span',[_vm._v("*If you send other assets to this address, you will be responsible for the loss.")])]),(
                _vm.currency === 'NEO' ||
                _vm.currency === 'GAS' ||
                _vm.currency === 'USDT-TRC20'
              )?_c('a-space',{staticClass:"inputContent inputContentHash",attrs:{"size":20,"direction":"vertical"}},[_c('div',{staticClass:"inputLabel"},[_vm._v("Transaction Hash")]),_c('a-input',{staticClass:"stepInput",attrs:{"value":_vm.tx_hash,"aria-placeholder":"Input transaction hash"},on:{"change":_vm.hashChange}})],1):_vm._e(),(
                _vm.currency === 'NEO' ||
                _vm.currency === 'GAS' ||
                _vm.currency === 'USDT-TRC20'
              )?_c('div',{staticClass:"stepsButtonContainer"},[_c('a-button',{staticClass:"stepsButton",attrs:{"type":"primary","disabled":_vm.submitDisabled},on:{"click":function($event){return _vm.submitHash()}}},[_vm._v(" Confirm ")])],1):_vm._e()],1)],1)])])],1),_c('NewFooter')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step3Container"},[_c('div',{staticClass:"registerTitle"},[_vm._v("Deposit")])])
}]

export { render, staticRenderFns }