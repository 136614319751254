<template>
  <div class="cardRechargeContent">
    <div class="cardRechargeContainer">
      <NavHeader />
      <NavHeaderMobile />
      <div class="cardRechargeContainerContent">
        <div class="backContainer" @click="backBtn">
          <a-icon type="arrow-left" /> back
        </div>
        <div class="stepContent">
          <div class="stepContainer">
            <div class="step3Container">
              <div class="registerTitle">Deposit</div>
            </div>
            <a-spin :spinning="loading">
              <a-space class="inputContent" :size="20" direction="vertical">
                <a-space
                  class="inputContent inputPayment"
                  :size="10"
                  direction="vertical"
                >
                  <div class="inputLabel">
                    <div class="inputLabelText">Deposit Assets</div>
                    <a-select :value="currency" @change="currencyChange">
                      <a-select-option
                        v-for="item of currencyOption"
                        :value="item.value"
                        :key="item.value"
                      >
                        <img
                          :src="item.logo"
                          :style="{
                            height: '24px',
                            width: '24px',
                            marginRight: '5px',
                            marginTop: '-3px',
                          }"
                        />
                        {{ item.label }}
                      </a-select-option>
                    </a-select>
                  </div>
                  <div
                    class="depositTips"
                    v-if="depositTransactionFee"
                  >
                    *Deposit Transaction Fee: {{ depositTransactionFee }} %
                  </div>
                  <div
                    class="depositTips"
                    v-else-if="cardTypeId!=='60000004'"
                  >*Deposit Transaction Fee: 2 %</div>
                  <div
                    class="depositTips"
                    v-else-if="cardTypeId==='60000004'"
                  >*Deposit Transaction Fee: 2.5 % (Minimum Fee: 1 USD)</div>
                  <div class="inputPaymentTips">
                    <span class="tipsLeft">Deposit Address: </span>
                    <span class="addressText">{{ address }}</span>
                    <div class="copyBtn" :data-clipboard-text="address">
                      <a-icon class="copyAddress" type="copy" theme="twoTone" />
                    </div>
                  </div>
                </a-space>
              </a-space>
              <div class="stepsBottomTips">
                <span v-if="currency === 'USDT-TRC20'"
                  >*Ensure the network for withdrawal is Tron(TRC20).</span
                >
                <span v-if="currency === 'NEO'"
                  >*Ensure the network for withdrawal is NEO(N3).</span
                >
                <span v-if="currency === 'GAS'"
                  >*Ensure the network for withdrawal is GAS(N3).</span
                >
                <span v-if="currency === 'USDT-TRC20'"
                  >*Send only USDT-TRC20 to this deposit address.</span
                >
                <span v-if="currency === 'NEO'"
                  >*Send only NEO to this deposit address.</span
                >
                <span v-if="currency === 'GAS'"
                  >*Send only GAS to this deposit address.</span
                >
                <span
                  >*If you send other assets to this address, you will be
                  responsible for the loss.</span
                >
              </div>
              <a-space
                class="inputContent inputContentHash"
                :size="20"
                direction="vertical"
                v-if="
                  currency === 'NEO' ||
                  currency === 'GAS' ||
                  currency === 'USDT-TRC20'
                "
              >
                <div class="inputLabel">Transaction Hash</div>
                <a-input
                  class="stepInput"
                  :value="tx_hash"
                  @change="hashChange"
                  aria-placeholder="Input transaction hash"
                />
              </a-space>
              <div
                class="stepsButtonContainer"
                v-if="
                  currency === 'NEO' ||
                  currency === 'GAS' ||
                  currency === 'USDT-TRC20'
                "
              >
                <a-button
                  class="stepsButton"
                  type="primary"
                  @click="submitHash()"
                  :disabled="submitDisabled"
                >
                  Confirm
                </a-button>
              </div>
            </a-spin>
          </div>
        </div>
      </div>
    </div>
    <NewFooter />
  </div>
</template>

<script>
import NavHeader from '@/components/NavHeader.vue';
import NavHeaderMobile from '@/components/NavHeaderMobile.vue';
import NewFooter from '@/components/NewFooter.vue';
import Clipboard from 'clipboard';
import {
  getUserAccount,
  submitTxHash,
  getDepositAddress,
} from '@/utils/api';
// import NEO from '@/assets/NEO.png';
// import GAS from '@/assets/gasImg.svg';
import USDT from '@/assets/USDT.png';

export default {
  name: 'Recharge',
  components: {
    NavHeader,
    NavHeaderMobile,
    NewFooter,
  },
  data() {
    return {
      loading: true,
      currency: 'USDT-TRC20',
      rechargeAmount: null,
      tx_hash: '',
      submitDisabled: true,
      address: '-',
      former_month_year: '',
      latter_month_year: '',
      currencyOption: [],
      list_asset: [],
      cardTypeId: '',
      depositTransactionFee: '',
    };
  },
  created() {
    const that = this;
    const clipboard = new Clipboard('.copyBtn');
    clipboard.on('success', (e) => {
      that.$message.success('Copy Success');
      e.clearSelection();
    });

    clipboard.on('error', (e) => {
      that.$message.error('Copy Failed');
      e.clearSelection();
    });

    this.getAccount();
    this.cardTypeId = this.$route.query.cardTypeId;
    this.depositTransactionFee = this.$route.query.depositTransactionFee;
  },
  methods: {
    getAccount() {
      getUserAccount(this.$route.query.id)
        .then((res) => {
          this.loading = false;
          this.list_asset = res.result.list_asset;
          res.result.list_asset.forEach((item) => {
            if (item.coin_type === 'NEO') {
              // this.currencyOption.push({
              //   value: item.coin_type,
              //   label: 'NEO(N3)',
              //   logo: NEO,
              // });
            } else if (item.coin_type === 'GAS') {
              // this.currencyOption.push({
              //   value: item.coin_type,
              //   label: 'GAS(N3)',
              //   logo: GAS,
              // });
            } else if (item.coin_type === 'USDT-TRC20') {
              this.currencyOption.push({
                value: item.coin_type,
                label: 'USDT-TRC20',
                logo: USDT,
              });
            } else {
              this.currencyOption.push({
                value: item.coin_type,
                label: item.coin_type,
              });
            }
          });
          // 获取默认地址NEO
          const params = {
            cardNo: this.$route.query.id,
            coinType: this.currency || 'USDT-TRC20',
          };
          getDepositAddress(params).then((depositRes) => {
            if (depositRes.code === 0) {
              this.address = depositRes.result.address;
            }
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    currencyChange(value) {
      this.address = '';
      this.currencyOption.forEach((item) => {
        if (item.value === value) {
          this.currency = item.value;
          const params = {
            cardNo: this.$route.query.id,
            coinType: item.value,
          };
          getDepositAddress(params).then((res) => {
            if (res.code === 0) {
              this.address = res.result.address;
            }
          });
        }
      });
    },
    backBtn() {
      this.$router.go(-1);
    },
    hashChange(e) {
      this.tx_hash = e.target.value;
      if (e.target.value.length === 64 || e.target.value.length === 66) {
        this.submitDisabled = false;
      } else {
        this.submitDisabled = true;
      }
    },
    submitHash() {
      const params = {
        tx_hash: this.tx_hash,
        card_type_id: this.$route.query.cardTypeId,
      };
      if (this.currency === 'NEO(N3)') {
        params.coin_type = 'NEO';
      } else if (this.currency === 'GAS(N3)') {
        params.coin_type = 'GAS';
      } else {
        params.coin_type = this.currency;
      }
      submitTxHash(params).then((res) => {
        if (res.code === 0) {
          this.tx_hash = '';
          this.$message.success('Success');
          this.$router.go(-1);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cardRechargeContent {
  background: linear-gradient(104.49deg, #fff4ef 0%, #edf6ff 99.7%);
  .cardRechargeContainer {
    max-width: 1280px;
    padding-top: 80px;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    min-height: calc(100vh - 180px);
    @media (max-width: 960px) {
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;
    }
    .cardRechargeContainerContent {
      max-width: 1280px;
      padding: 0 24px;
      margin: auto;
      @media (max-width: 960px) {
        padding: 0;
      }
      .backContainer {
        font-size: 18px;
        cursor: pointer;
      }
      .stepContent {
        max-width: 1280px;
        box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.08);
        background: #fff;
        border-radius: 4px;
        @media (max-width: 960px) {
          width: 100%;
        }
      }
      .stepContainer {
        width: 880px;
        margin: 24px auto;
        padding: 48px 24px;
        @media (max-width: 960px) {
          width: 100%;
        }
        .inputContent {
          width: 100%;
          .inputBottomBalance {
            color: #0045ff;
            font-size: 20px;
          }
          .inputLabel {
            font-size: 16px;
            .inputLabelText {
              margin-bottom: 10px;
            }
            .ant-select {
              height: 60px;
              .ant-select-selection--single {
                height: 60px;
              }
            }
          }
          .stepInput {
            height: 60px;
            border-radius: 8px;
          }
          .ant-select {
            width: 100%;
            height: 60px;
            /deep/ .ant-select-selection {
              height: 60px !important;
              border-radius: 8px;
              .ant-select-selection__rendered {
                height: 60px !important;
                line-height: 60px;
              }
            }
          }
          .ant-picker {
            height: 60px;
            width: 100%;
            border-radius: 8px;
          }
        }
        .inputContentHash {
          margin-top: 40px;
        }
        .inputPayment {
          .tipsLeft {
            width: 150px;
          }
          .depositTips {
            height: 16px;
            font-size: 14px;
            font-family: Poppins-Regular, Roboto;
            font-weight: 400;
            color: #0045ff;
            line-height: 16px;
          }
          .inputPaymentTips {
            font-size: 16px;
            .tipsOpacity {
              font-size: 12px;
              opacity: 0.5;
            }
            .addressText {
              word-wrap: break-word;
            }
            .copyBtn {
              margin-left: 2px;
              cursor: pointer;
              display: inline-block;
            }
          }
        }
        .step3Container {
          text-align: center;
          margin-bottom: 60px;
          .registerTitle {
            opacity: 0.8;
            color: #000;
            font-family: SamsungSharpSans-Bold;
            font-weight: bold;
            font-size: 40px;
          }
          .registerFee {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            .amount {
              color: #0045ff;
              font-size: 60px;
            }
            .currency {
              font-size: 20px;
            }
          }
          .describe {
            font-size: 14px;
            opacity: 0.5;
          }
        }
        .stepsBottomTips {
          margin-top: 40px;
          span {
            display: block;
            font-size: 14px;
            line-height: 22px;
            opacity: 0.5;
          }
        }
        .historyContainer {
          h2 {
            margin-bottom: 16px;
          }
          margin-top: 24px;
        }
        .searchContent {
          .searchHistory {
            background: #0045ff;
            color: #fff;
            margin-left: 48px;
          }
          @media (max-width: 750px) {
            display: flex;
            /deep/ .ant-calendar-picker {
              flex: 3;
            }
            .searchHistory {
              flex: 1;
            }
          }
        }
        /deep/ .ant-table-wrapper {
          width: 100%;
          overflow-x: auto;
          display: -webkit-box;
          -webkit-overflow-scrolling: touch;
          white-space: nowrap;
        }
      }
      .stepsButtonContainer {
        margin-top: 60px;
        text-align: center;
        .stepsButton {
          width: 300px;
          height: 60px;
          border-radius: 8px;
          margin-right: 20px;
          @media (max-width: 960px) {
            width: 100%;
            margin-bottom: 20px;
            margin-right: 0;
          }
        }
      }
    }
  }
}
.success {
  color: #52c41a;
}
.fail {
  color: red;
}
</style>
